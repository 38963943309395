/* --------------------------
color
-------------------------- */

$color-white: #fff;
$color-black: #000;

$color-light-gray: #f7f7f7;
$color-mid-gray: #f2f2f2;
$color-gray: #666;
$color-dark-gray: #808080;

$color-blue: #0b3f91;
$color-light-blue: #eef7fd;

/* --------------------------
spacing
-------------------------- */
$space: 80px, 56px, 30px, 20px;
$space-tab: 60px, 40px, 20px, 10px;
$space-sp: 40px, 30px, 20px, 10px;

/* --------------------------
font
-------------------------- */
$bold: 700;
$normal: 400;
$serif: "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "游明朝", "Yu Mincho",
  "游明朝体", "YuMincho", "ＭＳ Ｐ明朝", "MS PMincho", serif;
$sans-serif: "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "游ゴシック",
  "Yu Gothic", "游ゴシック体", YuGothic, "メイリオ", "Meiryo", "ＭＳ Ｐゴシック",
  "MS PGothic", sans-serif;
/* --------------------------
contents width
-------------------------- */
$contents-width: 1270px;
$contents-narrow-width: 880px;
$contents-padding: 20px;
$contents-padding-sp: 25px;

/* --------------------------
z-index order
-------------------------- */
$z: (contents, arrow, gnav, leftbar, header, footer, modal);
