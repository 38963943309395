/* --------------------------
Gナビ
-------------------------- */

.p-gnav {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 0.85);
  padding-top: 141px;
  z-index: 100;

  @include view-at(tab) {
    padding-top: 78px;
  }

  @include view-at(sp) {
    padding-top: 48px;
  }

  &-inner {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &-list {
    margin: -20px;

    &-item {
      text-align: center;

      &.disabled {
        a {
          opacity: 0.4;
          pointer-events: none;
        }
      }

      a {
        display: block;
        font-size: rem(24px);
        font-weight: $bold;
        letter-spacing: 0.06em;
        color: $color-blue;
        padding: 20px;
      }
    }
  }

  &-sns {
    margin-top: 40px;

    &-list {
      display: flex;
      justify-content: center;
      margin: -16px;

      &-item {
        padding: 16px;

        &.disabled {
          a {
            opacity: 0.4;
            pointer-events: none;
          }
        }
      }
    }
  }
}
