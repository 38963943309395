.top {
  &-section {
    margin: 80px 0;

    @include view-at(sp) {
      margin: 40px 0;
    }

    &-container {
      max-width: 1380px;
      margin: 0 auto;
      padding: 0 20px;

      @include view-at(sp) {
        width: 100%;
      }
    }
  }

  &-keyvisual {
    padding: 0 70px;
    margin-bottom: 30px;

    @include view-at(tab) {
      padding: 0 20px;
    }
  }

  &-slider {
    &-outer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 25px;
      padding: 0 30px;
    }

    &-dots {
      display: flex;

      > li {
        margin-right: 8px;

        &.slick-active button {
          color: #07358d;
          background: #07358d;
        }

        button {
          display: block;
          margin: 0;
          padding: 0;
          border: none;
          width: 15px;
          height: 15px;
          color: #e2e2e2;
          background: #e2e2e2;
          border-radius: 100%;
          cursor: pointer;
        }
      }
    }

    &-arrows {
      display: flex;
      margin: -8px;

      &-item {
        padding: 8px;
        cursor: pointer;

        @include view-at(sp) {
          img {
            width: 35px;
          }
        }
      }
    }
  }

  &-info {
    background: #efefef;
    border-top: 2px solid #ebebeb;
    border-bottom: 2px solid #ebebeb;

    .top-info-wrapper:first-child {
      border-bottom: 2px solid #ebebeb;
    }

    &-wrapper {
      padding: 20px 0;

      @include view-at(sp) {
        padding: 10px 0 16px;
      }
    }

    &-container {
      max-width: 1270px;
      margin: 0 auto;
      padding: 0 20px;
    }

    &-contents {
      display: flex;
      align-items: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      @include view-at(tab) {
        flex-wrap: wrap;
      }
    }

    &-schedule {
      width: 200px;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      border-right: 2px solid #e0e0e0;
      margin-right: 20px;

      @include view-at(tab) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 16px;
        border-right: none;
      }

      &.caution {
        justify-content: center;

        @include view-at(tab) {
          justify-content: flex-start;
        }
      }

      @include view-at(sp) {
        margin-bottom: 8px;
      }

      &-label {
        flex-shrink: 0;
        margin-right: 10px;
      }

      &-title {
        font-size: rem(18px);
        font-weight: $bold;
        color: $color-blue;
        line-height: 1;
        letter-spacing: 0.04em;

        &.caution {
          color: #e5004f;
        }
      }
    }

    // &-list {
    //   &-item {
    //     margin-bottom: 0.5em;

    //     &:last-child {
    //       margin-bottom: 0;
    //     }
    //   }
    // }

    &-text {
      letter-spacing: 0.1em;

      &.disabled {
        pointer-events: none;
      }

      @include view-at(sp) {
        // font-size: rem(14px) !important;
        font-size: rem(18px);
      }
    }
  }

  &-news {
    display: flex;
    margin: -20px;

    @include view-at(tab) {
      flex-wrap: wrap;
      margin: 0;
    }

    &-contents {
      padding: 20px;

      @include view-at(tab) {
        padding: 0;
      }
    }

    &-twitter {
      flex-shrink: 0;
      width: 340px;
      padding: 20px;

      @include view-at(tab) {
        width: 100%;
        padding: 0;
        margin-top: 30px;
      }
    }

    &-button {
      margin: 0;

      &--right {
        margin-left: auto;
      }
    }
  }

  &-survey {
    padding-left: 55px;
    display: flex;

    @include view-at(tab) {
      padding-left: 0;
      flex-wrap: wrap;
    }

    &-contents {
      margin-right: 80px;

      @include view-at(tab) {
        margin-right: 0;
      }
    }

    &-title {
      font-size: rem(32px);
      font-weight: $bold;
      letter-spacing: 0.06em;
      color: #e5004f;
      margin-bottom: 40px;
      text-align: center;
      position: relative;

      @include view-at(sp) {
        font-size: rem(20px);
        line-height: 1.5;
      }

      &::before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        background-image: url(../images/top/survey-title.png);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        position: absolute;
        bottom: -5px;
        left: 0;
        z-index: -1;
      }
    }

    &-image {
      margin-bottom: 50px;

      img {
        width: 100%;
      }

      @include view-at(tab) {
        margin-bottom: 25px;
      }
    }

    &-map {
      flex-shrink: 0;
      text-align: center;

      @include view-at(tab) {
        margin-top: 25px;
        width: 100%;
      }
    }
  }

  &-experience {
    margin: 80px 0;
    padding: 80px 0;
    background-color: #eef7fd;
    position: relative;

    @include view-at(sp) {
      margin: 40px 0;
      padding: 40px 0;
    }

    &-wrapper {
      padding-left: 55px;
      position: relative;

      @include view-at(tab) {
        padding-left: 0;
      }
    }

    &-copy {
      margin-bottom: 56px;

      @include view-at(mdpi) {
        width: 65%;
      }

      @include view-at(tab) {
        width: 100%;
      }

      @include view-at(sp) {
        margin-bottom: 28px;
      }

      &-item {
        margin-bottom: 16px;

        @include view-at(sp) {
          margin-bottom: 8px;
        }

        &:last-child {
          margin-bottom: 0;
        }

        .highlight {
          display: inline-block;
          font-size: rem(45px);
          font-weight: $bold;
          line-height: 1;
          color: $color-white;
          letter-spacing: 0.03em;
          padding: 10px 16px 10px;
          position: relative;
          z-index: 2;

          @include view-at(tab) {
            font-size: rem(32px);
          }

          @include view-at(sp) {
            font-size: 5.5vw;
            line-height: 1.2;
            padding: 4px 8px 4px;
            letter-spacing: 0;
          }

          &::before {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            background: linear-gradient(transparent 0%, #4cb83a 0%);
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
          }
        }

        .accent {
          color: #feff82;
        }
      }
    }

    &-eyecatch {
      position: absolute;
      right: 0;
      top: -60px;
      z-index: 1;

      @include view-at(mdpi) {
        width: 45%;
        text-align: right;
      }

      @include view-at(tab) {
        width: 100%;
        position: static;
        text-align: center;
        margin-top: 24px;
      }
    }

    &-text {
      @include view-at(mdpi) {
        width: 60%;
      }

      @include view-at(tab) {
        width: 100%;
      }
    }
  }

  &-marquee {
    height: 1em;
    width: 100%;
    overflow: hidden;

    @include view-at(sp) {
      font-size: rem(18px) !important;
    }

    @include view-at(tab) {
      a {
        display: inline-block;
        padding-left: 100%;
        white-space: nowrap;
        animation: marquee 10s linear infinite;

        @keyframes marquee {
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(-100%);
          }
        }
      }
    }

    @include view-at(sp) {
      a {
        font-size: rem(18px) !important;
      }
    }
  }

  &-calendar {
    &-container {
      width: 1050px;
      padding: 0 25px;
      max-width: 100%;
      margin: 0 auto;
    }
  }

  &-event {
    margin: 0 0 80px;
    padding: 80px 0;
    background: rgba(140, 73, 38, 0.15);

    &-card {
      display: block;
      text-align: center;

      &.disabled {
        pointer-events: none;
      }

      &-image {
        margin-bottom: 16px;
      }

      &-button {
        width: 60%;
        margin: 0 auto;
      }
    }
  }
}
