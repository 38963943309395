/* --------------------------
見出し
-------------------------- */

.c-h1 {
  font-size: rem(44px);
  font-weight: $bold;
  color: $color-blue;
  line-height: 1;
  letter-spacing: 0.06em;
  text-align: center;

  @include view-at(sp) {
    font-size: rem(28px);
  }
}

.c-h2 {
  font-size: rem(40px);
  font-weight: $bold;
  line-height: 1.5;
  letter-spacing: 0.06em;
  text-align: center;
  padding-bottom: 32px;
  margin-bottom: 64px;
  position: relative;

  @include view-at(sp) {
    font-size: rem(28px);
    padding-bottom: 16px;
    margin-bottom: 32px;
  }

  &::after {
    content: "";
    display: block;
    width: 150px;
    height: 8px;
    background-image: url(../images/common/h2-underline.png);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  small {
    display: block;
    font-size: rem(24px);
    font-weight: 400;
    margin-top: 30px;

    @include view-at(sp) {
      font-size: rem(22px);
      margin-top: 8px;
    }
  }
}

.c-h3 {
  font-size: rem(24px);
  font-weight: $bold;
  line-height: 1.4;
  letter-spacing: 0.06em;
  text-align: center;
  margin-bottom: 36px;
  position: relative;
  z-index: 1;

  @include view-at(sp) {
    margin-bottom: 24px;
  }

  span {
    position: relative;

    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 12px;
      background-image: url(../images/common/h3-underline.png);
      background-repeat: repeat-x;
      background-position: top center;
      position: absolute;
      top: calc(100% - 12px);
      left: 0;
      right: 0;
      margin: 0 auto;
      z-index: -1;
    }
  }
}

.c-h4 {
  font-size: rem(20px);
  line-height: 1.4;
  font-weight: $bold;
  letter-spacing: 0.06em;
  color: $color-blue;
  margin-bottom: 21px;
  text-align: center;

  @include view-at(sp) {
    margin-bottom: 12px;
  }
}

.c-h--center-line {
  @include center-line();
  margin-bottom: 8px;
}
