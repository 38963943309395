.p-floating {
  position: fixed;
  bottom: 30px;
  left: 30px;
  z-index: 10;

  @include view-at(sp) {
    bottom: 20px;
    left: 20px;
    width: 60%;
  }

  &-banner {
    position: relative;

    a {
      display: block;

      &.banner{
        transition: all 0.3s;
        img {
          box-shadow: 0 0 60px rgba(0, 0, 0, 0.25);
        }

        &:hover{
          opacity: 0.8;
        }
      }

      
    }

    &-close {
      position: absolute;
      top: -12px;
      right: -16px;
      cursor: pointer;
    }
  }
}
