/* --------------------------
フッター
-------------------------- */

.p-fnav {
  padding: 40px 0;
  background: #efefef;

  @include view-at(sp) {
    padding: 20px 0;
  }

  &-list {
    display: flex;
    align-items: center;
    justify-content: center;

    @include view-at(tab) {
      flex-wrap: wrap;
      justify-content: flex-start;
    }

    &-item {
      padding: 0 1em;
      border-right: 1px solid $color-black;

      @include view-at(tab) {
        padding: 0;
        border-right: none;
        width: 50%;
      }

      &:last-child {
        border-right: none;
      }

      &.disabled {
        a {
          opacity: 0.4;
          pointer-events: none;
        }
      }

      a {
        display: block;
        font-size: rem(15px);

        letter-spacing: 0.06em;
        font-weight: 500;

        @include view-at(tab) {
          text-align: center;
          padding: 1em;
          line-height: 1.2;
        }
      }
    }
  }
}
