/* --------------------------
フッター
-------------------------- */

.p-footer {
  &-container {
    max-width: 1270px;
    padding: 0 20px;
    margin: 0 auto;
  }

  &-yoshinogari {
    padding: 60px 0;
    background: #fdfcfa;

    @include view-at(sp) {
      padding: 30px 0;
    }

    &-container {
      max-width: 875px;
      margin: 0 auto;
      padding: 0 20px;

      @include view-at(sp) {
        width: 100%;
      }
    }

    &-h2 {
      font-size: rem(20px);
      font-weight: $bold;
      line-height: 1.6;
      letter-spacing: 0.06em;
      text-align: center;
      margin-bottom: 16px;
    }

    &-h3 {
      font-size: rem(16px);
      font-weight: $bold;
      line-height: 1.6;
      letter-spacing: 0.1em;
      margin-bottom: 8px;

      @include view-at(sp) {
        font-size: rem(14px);
        line-height: 1.2;
      }

      span {
        color: #94252a;
      }
    }

    &-info {
      width: calc(100% - 575px);
      padding: 15px;

      @include view-at(tab) {
        width: 100%;

        &-item {
          width: 50%;
          padding: 10px;
        }
      }
    }

    &-map {
      width: 575px;
      padding: 15px;

      @include view-at(tab) {
        width: 100%;
      }
    }

    &-inner {
      height: 100%;
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      @include view-at(tab) {
        &--tab {
          display: flex;
          justify-content: space-between;
          flex-direction: column;
        }
      }

      @include view-at(tab) {
        height: auto;
        justify-content: flex-start;
        flex-direction: row;
        flex-wrap: wrap;
        margin: -10px;
      }
    }

    &-text {
      margin-top: 16px;
      font-size: rem(11px);
      line-height: 1.4;
      letter-spacing: 0.06em;

      @include view-at(tab) {
        margin-top: 0px;
      }
    }
  }

  &-banner {
    margin-top: 60px;
    text-align: center;

    @include view-at(sp) {
      margin-top: 30px;
    }
  }

  &-info {
    background: #efefef;
    padding: 60px 0;

    @include view-at(sp) {
      padding: 30px 0;
    }

    &-inner {
      display: flex;
      justify-content: space-between;

      @include view-at(sp) {
        flex-wrap: wrap;
      }
    }

    &-text {
      font-size: rem(16px);
      color: #616161;
      letter-spacing: 0.08em;

      &.hidden {
        visibility: hidden;
      }

      &--line {
        padding-bottom: 9px;
        border-bottom: 2px solid #dfdfdf;
        margin-bottom: 10px;
      }
    }
  }

  &-produced {
    @include view-at(sp) {
      width: 100%;
      text-align: center;
      margin-bottom: 15px;

      img {
        height: 40px;
      }
    }
  }

  &-address {
    flex-shrink: 0;
    width: 560px;

    @include view-at(tab) {
      width: auto;
    }

    @include view-at(sp) {
      width: 100%;
      margin-bottom: 15px;
    }

    &-place {
      font-size: rem(20px);
      font-weight: $bold;
      line-height: 1.6;
      letter-spacing: 0.06em;
      margin-bottom: 0;
    }

    &-tel {
      font-size: rem(14px);
      line-height: 1.5;
      letter-spacing: 0.06em;
    }
  }

  &-sns {
    @include view-at(sp) {
      width: 100%;
    }

    &-list {
      display: flex;
      margin: -10px;

      @include view-at(sp) {
        justify-content: center;
      }

      &-item {
        padding: 10px;

        &.disabled {
          a {
            opacity: 0.4;
            pointer-events: none;
          }
        }
      }
    }
  }

  &-lower {
    margin: 40px 0;

    @include view-at(sp) {
      margin: 20px 0;
    }

    &-inner {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include view-at(sp) {
        flex-wrap: wrap;
        justify-content: center;
      }
    }
  }

  &-sitemap {
    @include view-at(sp) {
      margin-bottom: 20px;
      width: 100%;
    }

    &-list {
      display: flex;
      margin: 0 -2em;

      @include view-at(sp) {
        margin: 0 -1em;
      }

      &-item {
        padding: 0 2em;
        border-right: 1px solid $color-black;

        @include view-at(sp) {
          width: 100%;
          padding: 0 1em;
          text-align: center;
        }

        &:last-child {
          border-right: none;
        }

        a {
          display: block;
          font-size: rem(13px);
          line-height: 1;
          letter-spacing: 0.06em;
        }
      }
    }
  }

  &-copyright {
    font-size: rem(11px);
    letter-spacing: 0.06em;
  }

  &-song {
    padding: 60px 0;
    border-bottom: 1px solid #e0e0e0;

    @include view-at(sp) {
      padding: 30px;
    }

    &-list {
      display: flex;
      margin: -15px auto;
      max-width: 780px;

      @include view-at(sp) {
        display: block;
        margin: 0;
      }

      li {
        padding: 15px;

        @include view-at(sp) {
          padding: 0;
        }
      }

      a {
        display: block;
        text-decoration: none;
        height: 214px;

        @include view-at(sp) {
          height: auto;
        }

        &.qr {
          border: 1px solid #e0e0e0;
        }

        img {
          height: 100%;
          width: auto;

          @include view-at(sp) {
            height: auto;
          }
        }
      }
    }
  }
}
