/* --------------------------
リスト
-------------------------- */

.c-list {
  font-size: rem(16px);

  > li {
    line-height: 1.5;
    margin-bottom: 10px;
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &--point {
    > li {
      padding-left: 1.25em;
      position: relative;

      &::before {
        content: "";
        width: 10px;
        height: 10px;
        border-radius: 50%;
        display: inline-block;
        background-color: $color-blue;
        position: absolute;
        top: 7px;
        left: 0;
      }
    }
  }

  &--order {
    padding-left: 1.25em;
  }
}
