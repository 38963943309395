/* --------------------------
背景色
-------------------------- */

.u-bg-light {
  background-color: $color-mid-gray;
}

.u-bg-white {
  background-color: $color-white;
}

.u-bg-black {
  background-color: $color-black;
}

.u-bg-blue {
  background-color: $color-light-blue;
}