/* --------------------------
ボタン
-------------------------- */

.c-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 360px;
  height: 70px;
  font-size: rem(22px);
  line-height: 1;
  font-weight: $bold;
  letter-spacing: 0.1em;
  color: $color-white;
  padding: 10px 20px 10px 10px;
  background: linear-gradient(
    90deg,
    rgb(98, 191, 36) 0%,
    rgb(33, 170, 101) 100%
  );
  border-radius: 15px;
  box-shadow: 0px 6px 0px 0px rgba(0, 0, 0, 0.08);
  position: relative;
  margin: 0 auto;

  @include view-at(sp) {
    width: 100%;
    font-size: rem(18px);
  }

  &::after {
    content: "";
    display: block;
    width: 12px;
    height: 12px;
    border-top: 2px solid $color-white;
    border-right: 2px solid $color-white;
    transform: rotate(45deg);
    position: absolute;
    top: calc(50% - 6px);
    right: 30px;
  }

  &:hover {
    opacity: 0.6;
  }
}
