/* --------------------------
パネル
-------------------------- */

.c-panel {
  background-color: $color-white;
  border-radius: 10px;

  &--border {
    border: 2px solid #ebebeb;
  }

  &-h {
    margin-bottom: 20px;
  }

  &-contents {
    padding: 30px 40px;

    @include view-at(sp) {
      padding: 20px 15px;
    }

    &--narrow {
      padding: 15px;
    }
  }
}
