.p-asidebar {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;

  // &--left {
  //   left: 25px;

  //   a {
  //     writing-mode: vertical-rl;
  //     font-size: rem(20px);
  //     font-weight: 700;
  //     line-height: 1;
  //     letter-spacing: 0.08em;
  //     color: #07358d;
  //   }
  // }

  &--right {
    right: 0;
    width: 50px;

    @include view-at(sp) {
      width: 40px;
    }

    a {
      @include view-at(sp) {
        font-size: rem(16px);
        padding: 16px 0;

        img {
          width: 24px;
        }
      }

      &.live {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        width: 100%;
        padding: 20px 0;
        writing-mode: vertical-rl;
        font-size: rem(18px);
        font-weight: 700;
        line-height: 1;
        color: $color-white;
        letter-spacing: 0.18em;
        border-radius: 10px 0 0 10px;
        background: linear-gradient(to top, #21aa65, #62bf24);
      }

      &.disabled {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        width: 100%;
        padding: 20px 0;
        writing-mode: vertical-rl;
        font-size: rem(18px);
        font-weight: 700;
        line-height: 1;
        color: $color-white;
        letter-spacing: 0.18em;
        border-radius: 10px 0 0 10px;
        background: #288dd1;
        pointer-events: none;

        // @include view-at(sp) {
        //   &--image {
        //     width: 40px !important;
        //   }
        // }
      }
    }
  }
}
