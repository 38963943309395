/* --------------------------
ハンバーガーメニューアイコン
-------------------------- */

.c-menu {
  display: block;
  width: 32px;
  height: 26px;
  position: relative;
  z-index: 10000;

  @include view-at(sp) {
    width: 16px;
    height: 16px;
  }

  &-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
  }

  &-trigger,
  &-trigger span {
    display: inline-block;
    transition: all 0.2s;
    box-sizing: border-box;
  }

  &-trigger {
    width: 32px;
    height: 26px;
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-direction: column;

    @include view-at(sp) {
      width: 16px;
      height: 16px;
    }

    &-label {
      margin-left: 15px;
      font-size: rem(16px);
      color: #07358d;
      font-weight: $bold;
      line-height: 1;
      letter-spacing: 0.06em;
      position: relative;
      z-index: 10000;

      @include view-at(sp) {
        margin-left: 4px;
        font-size: rem(14px);
      }
    }
  }

  &-trigger span {
    height: 3px;
    border-radius: 10px;
    background-color: #07358d;
    // position: absolute;
    // right: 0;
  }

  &-trigger span:nth-of-type(1) {
    width: 100%;
  }

  &-trigger span:nth-of-type(2) {
    width: 80%;
  }

  &-trigger span:nth-of-type(3) {
    width: 60%;
  }

  &-trigger.active span:nth-of-type(1) {
    transform: translateY(12px) rotate(45deg);

    @include view-at(sp) {
      transform: translateY(6.5px) rotate(45deg);
    }
  }

  &-trigger.active span:nth-of-type(2) {
    opacity: 0;
  }

  &-trigger.active span:nth-of-type(3) {
    transform: translateY(-12px) rotate(-45deg);
    width: 100%;

    @include view-at(sp) {
      transform: translateY(-6.5px) rotate(-45deg);
    }
  }
}
