/* --------------------------
ヘッダー
-------------------------- */

.p-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  background: $color-white;

  &-inner {
    padding: 45px 70px 45px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include view-at(tab) {
      padding: 24px;
    }

    @include view-at(sp) {
      padding: 16px 8px;
    }
  }

  &-logo {
    font-size: 0;
    line-height: 1;
    text-align: left;

    a {
      display: inline-block;

      &:hover,
      &:hover img {
        opacity: 1;
      }

      img {
        height: 51px;

        @include view-at(tab) {
          height: 30px;
        }

        @include view-at(sp) {
          height: 17px;
        }
      }
    }
  }
}
