.museum {
  &-bg {
    background: #0097d6;
  }

  &-map {
    background-image: url(../images/museum/nazoarea-bg.png);
    background-size: cover;
  }

  &-panel {
    background: $color-white;
    border-radius: 40px;
    position: relative;
    z-index: 1;

    &-contents {
      padding: 56px 40px;

      @include view-at(sp) {
        padding: 32px 24px;
      }
    }

    &-h {
      font-size: rem(56px);
      font-weight: $bold;
      color: #6a3906;
      line-height: 1.5;
      letter-spacing: 0.05em;
      text-align: center;

      @include view-at(sp) {
        font-size: rem(24px);
      }
    }

    &-headline {
      font-size: rem(24px);
      font-weight: $bold;
      color: #6a3906;
      line-height: 1.5;
      letter-spacing: 0.05em;
      margin-bottom: 8px;

      @include view-at(sp) {
        font-size: rem(24px);
      }
    }
  }

  &-accent {
    font-weight: $bold;
  }

  &-link {
    // text-decoration: underline;

    &--red {
      color: #e5004f;
    }
  }

  &-bottom {
    padding-top: 40px;
    position: relative;

    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 0;
      // 画像の高さ ÷ 画像の幅 × 100
      padding-bottom: calc(520 / 1340 * 100%);
      margin-top: calc(520 / 1340 * -50%);
      background-image: url(../images/museum/section-bottom.png);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      position: relative;
      z-index: 2;
    }
  }

  &-label {
    font-size: rem(20px);
    line-height: 1;
    letter-spacing: 0.1em;
    color: $color-white;
    padding: 0 0.75em;

    &.ar {
      background: #e07f00;
    }

    &.data {
      background: #df147d;
    }
  }
}
