.wp-pagenavi {
  text-align: center;

  .pages {
    display: none;
  }
  span,
  a {
    /*数字部分の共通CSS　大きさなど*/
    display: inline-block;
    margin: 0 5px;
    width: 32px;
    height: 32px;
    line-height: 28px;
    text-align: center;
    color: $color-blue;
    border: 2px solid $color-blue;
    text-decoration: none;

    &.current,
    &:hover {
      /*現在のページ*/
      color: $color-white;
      background: $color-blue;
    }

    &.nextpostslink,
    &.previouspostslink {
      display: none;
    }
  }
}
