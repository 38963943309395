.entry-content,
.block-editor-block-list__layout {
  h2 {
    @extend .c-h2;

    em {
      display: block;
      font-style: normal;
      font-size: rem(24px);
      font-weight: 400;
      margin-top: 30px;

      @include view-at(sp) {
        font-size: rem(22px);
        margin-top: 8px;
      }
    }
  }
  h3 {
    @extend .c-h3;

    strong {
      position: relative;

      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 12px;
        background-image: url(../images/common/h3-underline.png);
        background-repeat: repeat-x;
        background-position: top center;
        position: absolute;
        top: calc(100% - 12px);
        left: 0;
        right: 0;
        margin: 0 auto;
        z-index: -1;
      }
    }
  }

  h4 {
    @extend .c-h4;

    em {
      @extend .u-font-default;
      font-style: normal;
    }
  }

  ul {
    @extend .c-list;
    @extend .c-list--point;
  }

  ol {
    @extend .c-list;
    @extend .c-list--order;
  }

  table {
    @extend .c-table;
  }

  .wp-block-group {
    @extend .c-panel;
    @extend .c-panel--border;

    &__inner-container {
      @extend .c-panel-contents;
    }
  }

  .wp-block-embed__wrapper {
    position: relative;
    width: 100%;
    padding-top: 56.25%;

    iframe {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;

      & ~ p {
        display: none;
      }
    }
  }
}
