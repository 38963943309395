/* --------------------------
テーブル
-------------------------- */

.c-table {
  width: 100%;
  border-collapse: separate;
  border-radius: 10px;
  overflow: hidden;
  font-size: rem(16px);
  line-height: 2;
  letter-spacing: 0.1em;

  @include view-at(sp) {
    line-height: 1.5;
  }

  tbody {
    tr {
      th:last-child,
      td:last-child {
        border-right: none;
      }

      &:nth-child(2n) td {
        background: #f7f7f7;
      }

      &:nth-child(2n-1) td {
        background: #f2f2f2;
      }
    }
    th {
      width: 270px;
      max-width: 100%;
      font-weight: $bold;
      color: $color-blue;
      padding: 1.5em;
      background: #eaf5fd;
      text-align: center;
      border-right: 2px solid $color-white;
      border-bottom: 2px solid $color-white;

      @include view-at(sp) {
        width: auto;
      }
    }
    td {
      font-weight: $normal;
      padding: 1.5em;
      border-right: 2px solid $color-white;
      border-bottom: 2px solid $color-white;

      // @include view-at(sp) {
      //   display: block;
      //   width: 100%;
      //   border-bottom: 1px solid $color-gray;
      //   padding: 10px 15px;
      // }
    }
  }
}
