/* --------------------------
リンク
-------------------------- */

.c-link {
  text-decoration: underline;

  &--primary {
    // text-decoration: none;
    font-weight: $bold;
    letter-spacing: 0.06em;
    color: #24ab63;
    // padding-bottom: 0.5em;
    // border-bottom: 1px solid #24ab63;

    @include view-at(sp) {
      line-height: 1.5;
    }

    &::after {
      content: "＞";
      display: inline-block;
      transform: scaleX(0.6);
      margin-left: 0.5em;
    }
  }

  &--block {
    display: block;

    img {
      display: block;
    }
  }
}
