.p-mv {
  padding: 68px 0;
  background-size: auto auto;
  background-color: $color-white;
  background-image: repeating-linear-gradient(
    -45deg,
    transparent,
    transparent 10px,
    #e2f2fe 10px,
    #e2f2fe 20px
  );
  position: relative;
  margin-bottom: 80px;

  @include view-at(sp) {
    padding: 40px 0;
    margin-bottom: 40px;
  }
}
