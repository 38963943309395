/* --------------------------
テキスト装飾
-------------------------- */

.u-text-accent {
  color: $color-gray;
}

.u-text-important {
  color: #e4004e;
}

.u-text-inverse {
  color: $color-white;
}

.u-text-sub {
  color: #666666;
}

.u-text-serif {
  font-family: "Times New Roman", Times, serif;
}

.u-text-bold {
  font-weight: $bold;
}

.u-text-normal {
  font-weight: $normal;
}

.u-text-highlight {
  background: linear-gradient(transparent 70%, $color-gray 70%);
}

.u-text-x-small {
  font-size: rem(13px);
  line-height: 2;
}

.u-text-small {
  font-size: rem(14px);
  line-height: 1.4;
}

.u-text-default {
  font-size: rem(16px);
  line-height: 1.6;
}

.u-text-large {
  font-size: rem(18px);
  line-height: 1.8;
}

.u-text-x-large {
  font-size: rem(20px);
  line-height: 2;
}

.u-font-default {
  font-size: rem(16px);
}

.u-font-small {
  font-size: rem(14px);
}
