.experience {
  &-hero {
    position: relative;

    img {
      display: block;
    }

    &-illust {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;

      padding-top: 15%;
      background-image: url(../images/experience/illust.png);
      background-position: bottom center;
      background-repeat: no-repeat;
      background-size: cover;

      &::before {
        content: "";
        display: block;
        width: 100%;
        height: 0;
        padding-top: 15%;
        background-image: url(../images/experience/illust-tree-left.png);
        background-position: bottom left;
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        bottom: -10%;
        left: 0;
        z-index: 1;
      }

      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 0;
        padding-top: 15%;
        background-image: url(../images/experience/illust-tree-right.png);
        background-position: bottom right;
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        bottom: -10%;
        right: 0;
        z-index: 1;
      }
    }
  }

  &-illust {
    &-eyecatch {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0 auto -2%;
      z-index: 2;
    }
  }

  &-inner {
    padding: 80px 0;
    // background-image: url(../images/experience/bg-layer.jpg);
    // background-repeat: no-repeat;
    // background-size: cover;
    // background-position: center center;
    // position: relative;

    &.neko {
      padding: 80px 0;
      background-image: url(../images/experience/bg-layer.jpg);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      position: relative;

      &::before {
        content: "";
        display: block;
        width: 422px;
        height: 383px;
        background-image: url(../images/experience/illust-eyecatch--right.png);
        background-position: top right;
        background-repeat: no-repeat;
        position: absolute;
        top: 2em;
        right: 2em;
        z-index: 1;
      }

      &::after {
        content: "";
        display: block;
        width: 137px;
        height: 168px;
        background-image: url(../images/experience/illust-eyecatch--left.png);
        background-position: bottom left;
        background-repeat: no-repeat;
        position: absolute;
        bottom: 2em;
        left: 2em;
        z-index: 1;
      }
    }
  }

  &-container {
    max-width: 975px;
    margin: 0 auto;
    padding: 0 20px;
    position: relative;
    z-index: 2;

    @include view-at(sp) {
      width: 100%;
    }
  }

  &-panel {
    background: #fff;
    border-radius: 20px;
    border: 2px solid #ebebeb;

    &.neko {
      background: #fff;
      border-radius: 20px;
    }

    &-contents {
      padding: 40px 30px 60px;

      @include view-at(sp) {
        padding: 20px;
      }
    }
  }

  &-table {
    width: 100%;
    line-height: 1.5;

    tbody {
      th {
        width: 20%;
        padding: 20px;
        vertical-align: top;

        &.experience-table-h {
          vertical-align: middle;

          @include view-at(sp) {
            padding: 1em 0 0.5em;
            border-right: none;
            border-bottom: none;
            margin-bottom: 0;
          }
        }

        @include view-at(sp) {
          display: block;
          width: 100%;
          padding: 0;
          margin-bottom: 16px;
        }
      }

      td {
        padding: 20px;
        font-size: rem(20px);
        font-weight: $bold;
        line-height: 1.5;
        letter-spacing: 0.06em;

        @include view-at(sp) {
          display: block;
          width: 100%;
          padding: 0;
          font-size: rem(18px);
          margin-bottom: 16px;
        }

        &.experience-table-item {
          @include view-at(sp) {
            padding-bottom: 1.5em;
            margin-bottom: 0;
            border-bottom: none;
          }
        }
      }
    }

    &-label {
      font-size: rem(16px);
      line-height: 1;
      letter-spacing: 0.06em;
      color: #23ab63;
      padding: 0.5em;
      border: 2px solid #24ab63;
      border-radius: 1em;
      text-align: center;
    }
  }

  &-caution {
    font-size: rem(14px);
    font-weight: $normal;
    line-height: 1.5;
    color: #666;
    letter-spacing: 0.03em;
    margin-top: 8px;

    &-link {
      color: #666;
      text-decoration: underline;
    }
  }

  &-accent {
    color: #e5004f;
  }
}
