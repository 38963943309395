.p-column {
  display: flex;
  justify-content: space-between;

  @include view-at(tab) {
    flex-wrap: wrap;
  }

  &-main {
    flex-grow: 1;
    padding-right: 82px;

    @include view-at(tab) {
      padding-right: 0;
      margin-bottom: 30px;
    }
  }

  &-sidebar {
    flex-shrink: 0;
    flex-basis: 320px;

    @include view-at(tab) {
      flex-basis: auto;
      width: 100%;

      &-inner {
        display: flex;
        margin: -15px;

        @include view-at(sp) {
          flex-wrap: wrap;
        }
      }

      &-item {
        width: 50%;
        padding: 15px;

        @include view-at(sp) {
          width: 100%;
        }
      }
    }

    &-menu {
      padding: 50px 30px;
      background: #f7f7f7;
      border-radius: 10px;
      margin-bottom: 30px;

      @include view-at(tab) {
        margin-bottom: 0;
      }

      @include view-at(sp) {
        padding: 30px 20px;
      }
    }

    &-twitter {
      width: 100%;
    }
  }
}
