$all: #e36a94;
$news: #6887c5;
$news-line: #748ec3;
$events: #cf7f66;
$events-line: #dc9c68;
$highlight: #4ebdb8;
$highlight-line: #71c2be;

.p-news {
  &-category {
    margin-bottom: 40px;

    &-list {
      display: flex;
      margin: -8px;

      @include view-at(sp) {
        flex-wrap: wrap;
      }

      &-item {
        padding: 8px;

        &.active,
        &:hover {
          a {
            color: $color-white !important;

            &.all {
              background: $all;
              border-color: $all;

              &::after {
                border-top-color: $all;
              }
            }

            &.news {
              background: $news;
              border-color: $news;

              &::after {
                border-top-color: $news;
              }
            }

            &.events {
              background: $events;
              border-color: $events;

              &::after {
                border-top-color: $events;
              }
            }

            &.highlight {
              background: $highlight;
              border-color: $highlight;

              &::after {
                border-top-color: $highlight;
              }
            }
          }
        }

        @include view-at(sp) {
          width: 50%;
        }

        a {
          display: block;
          font-size: rem(15px);
          font-weight: $bold;
          letter-spacing: 0.06em;
          padding: 12px 32px;
          border: 2px solid transparent;
          position: relative;

          &::after {
            content: "";
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 10px 10px 0 10px;
            border-color: transparent;
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            margin: 0 auto;
          }

          @include view-at(sp) {
            font-size: rem(14px);
            padding: 12px 8px;
            text-align: center;
          }

          &.all {
            color: $all;
            border-color: $all;
          }

          &.news {
            color: $news;
            border-color: $news-line;
          }

          &.events {
            color: $events;
            border-color: $events-line;
          }

          &.highlight {
            color: $highlight;
            border-color: $highlight-line;
          }
        }
      }
    }
  }

  &-list {
    display: flex;
    flex-wrap: wrap;
    margin: -30px -10px;

    @include view-at(sp) {
      margin: -10px -5px;
    }

    &-item {
      width: calc(100% / 4);
      padding: 30px 10px;

      @include view-at(sp) {
        width: 100%;
        padding: 10px 5px;
      }

      a {
        display: block;

        img {
          width: 100%;
        }
      }
    }
  }

  &-thumbnail {
    position: relative;
    margin-bottom: 28px;

    img {
      width: 100%;
    }
  }

  &-label {
    font-size: rem(12px);
    font-weight: $bold;
    line-height: 1;
    letter-spacing: 0.08em;
    padding: 8px 16px 4px 16px;
    background: $color-white;
    position: absolute;
    left: 0;
    bottom: -4px;

    &.news {
      color: $news;
    }

    &.events {
      color: $events;
    }

    &.highlight {
      color: $highlight;
    }
  }

  &-date {
    font-size: rem(14px);
    font-style: italic;
    line-height: 1;
    letter-spacing: 0.06em;
  }

  &-title {
    font-size: rem(15px);
    font-weight: $bold;
    line-height: 1.85;
    letter-spacing: 0.06em;
  }

  &-pagenavi {
    &-list {
      display: flex;
      justify-content: space-between;
      margin: -10px;

      &-item {
        padding: 10px;

        @include view-at(sp) {
          width: calc(100% / 3);
        }

        a {
          display: block;
          font-weight: $bold;
          letter-spacing: 0.1em;
          color: $color-blue;
          padding: 1em 3em;
          border: 2px solid $color-blue;
          position: relative;
          text-align: center;

          @include view-at(sp) {
            padding: 1em;
          }

          &.back {
            padding: 1em 4em;

            @include view-at(sp) {
              padding: 1em;
            }
          }

          &.next {
            &::before {
              content: "<";
              display: block;
              color: $color-blue;
              position: absolute;
              left: 0.5em;
              top: 50%;
              transform: scale(0.5, 1) translateY(-50%);

              @include view-at(sp) {
                left: 0.25em;
              }
            }

            @include view-at(sp) {
              margin-right: 0.25em;
              // text-align: right;
            }

            &.none {
              visibility: hidden;
            }
          }

          &.prev {
            &::after {
              content: ">";
              display: block;
              color: $color-blue;
              position: absolute;
              right: 0.5em;
              top: 50%;
              transform: scale(0.5, 1) translateY(-50%);

              @include view-at(sp) {
                right: 0.25em;
              }
            }

            @include view-at(sp) {
              margin-left: 0.25em;
              // text-align: left;
            }

            &.none {
              visibility: hidden;
            }
          }

          &:hover {
            color: $color-white;
            background: $color-blue;

            &.prev {
              &::after {
                color: $color-white;
              }
            }

            &.next {
              &::before {
                color: $color-white;
              }
            }
          }
        }
      }
    }
  }
}
