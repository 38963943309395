html {
  font-size: 16px;
}

body {
  font-feature-settings: "pkna";
  font-family: "Noto Sans JP";
  font-weight: $normal;
  color: $color-black;
  font-size: rem(16px);

  
}

a {
  color: $color-black;
  text-decoration: none;
  transition: all 0.3s;

  &:hover {
    opacity: 0.8;

    img {
      opacity: 0.8;
    }
  }
}

p {
  font-weight: $normal;
  line-height: 2;
  letter-spacing: 0.1em;
  margin-bottom: 1em;

  &:last-child {
    margin-bottom: 0;
  }
}

img {
  width: auto;
  height: auto;
  max-width: 100%;
}

.main {
  padding-top: 141px;

  @include view-at(tab) {
    padding-top: 78px;
  }

  @include view-at(sp) {
    padding-top: 49px;
  }
}
